import React, { Suspense, lazy, ReactElement } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { MENU_ITEMS } from '@/components/navigation/constants';
import { showLeftNav } from '@/states/showLeftNav';
import ErrorScreen from '@/components/error-screen/ErrorScreen';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import WorkflowsV2Wrapper from '@/screens/workflowv2';
import FactsLoader from '@/components/loader/Loader';
import CapabilitiesV2Wrapper from '@/screens/capabilitiesV2/CapabilitiesV2Wrapper';
import WorkflowsWrapper from '@/screens/workflows/workflowsWrapper';
import WebhooksWrapper from '@/screens/webhooks-v2/WebhooksWrapper';
import Sider from '@/layout/Sider';
import MainContent from '@/layout/MainContent';
import NavigationBar from '@/components/navigation/NavigationBar';
import { useDocumentation } from '@/queries/UseDocumentation';
import { Flex, Link } from '@chakra-ui/react';
import FAQs from '@/screens/faqs/Faqs';
import { TrialBanner } from '@/components/trial-banner/TrialBanner';
import useGetSubscription from '@/components/trial-banner/queries/useGetSubscriptio';
import Layout from '../layout/Layout';
import { FeatureFlags } from '../types/common';

// const BillingsWrapper = lazy(() => import('@/screens/billing'));
const TransactionsWrapper = lazy(() => import('@/screens/transactions/TransactionsWrapper'));
const BulkVerificationWrapper = lazy(() => import('@/screens/bulk-verification/BulkVerificationWrapper'));
const EntityListWrapper = lazy(() => import('@/screens/entity-list/EntityListWrapper'));
const SettingList = lazy(() => import('@/screens/settings/Settings'));
const InsightsDashboard = lazy(() => import('@/screens/insights/Insights'));
const LogList = lazy(() => import('@/screens/log-list/LogList'));
const UserList = lazy(() => import('@/screens/admin/UserList'));
const BlockListWrapper = lazy(() => import('@/screens/blocklist/BlockListWrapper'));
const AuditList = lazy(() => import('@/screens/audit-list/AuditList'));
const SessionList = lazy(() => import('@/screens/session-list/SessionList'));
const Dashboard = lazy(() => import('@/screens/superset-dashboards/Dashboard'));
const SDKDemosWrapper = lazy(() => import('@/screens/sdk-demos/SDKDemosWrapper'));
const ApiKeyList = lazy(() => import('@/screens/admin/ApiKeyList'));
const DeviceAndBehavior = lazy(() => import('@/screens/device-and-behavior/DeviceAndBehaviour'));

export const AppLandingContent = (): React.ReactElement => {
  const isShowLeftNav = useRecoilValue(showLeftNav);
  const { featureFlag, getFeatureFlag } = useFeatureFlag();
  const { data: subscriptonPlans, isSuccess } = useGetSubscription();
  const { data: docPath } = useDocumentation();

  const MENU_ITEMS_FEATURE_FLAG = MENU_ITEMS.filter(({ id }) => {
    const key = id as keyof FeatureFlags;
    return key in featureFlag ? getFeatureFlag(key, 'read') : true;
  });

  return (
    <Layout>
      {subscriptonPlans?.planId?.includes('free') && isSuccess && <TrialBanner />}

      <Layout hasSider styles={{ position: 'relative', overflow: 'hidden' }}>
        {isShowLeftNav && (
          <Sider>
            <NavigationBar navItems={MENU_ITEMS_FEATURE_FLAG} />
          </Sider>
        )}

        <MainContent>
          <Suspense fallback={<FactsLoader />}>
            <Switch>
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>
              <Route path="/dashboard" component={Dashboard} />

              {getFeatureFlag('workflowVersion', 'isWorkflowV2')
                ? getFeatureFlag('workflows', 'read') && (
                    <Route
                      path="/workflows"
                      render={() => {
                        return <WorkflowsV2Wrapper />;
                      }}
                    />
                  )
                : getFeatureFlag('workflows', 'read') && <Route path="/workflows" component={WorkflowsWrapper} />}
              {getFeatureFlag('customCapabilities', 'read') && (
                <Route path="/capabilities" component={CapabilitiesV2Wrapper} />
              )}
              <Route path="/reports" component={TransactionsWrapper} />
              {getFeatureFlag('entities', 'read') && <Route path="/entities" component={EntityListWrapper} />}
              <Route path="/settings" component={SettingList} />
              <Route path="/insights" component={InsightsDashboard} />
              <Route exact path="/logs/api-logs" component={LogList} />
              <Route exact path="/logs/device-and-behavior" component={DeviceAndBehavior} />
              <Route exact path="/logs/audits" component={AuditList} />
              <Route exact path="/logs/sessions" component={SessionList} />
              <Route exact path="/admin/api-keys" component={ApiKeyList} />
              <Route exact path="/admin/users" component={UserList} />
              {getFeatureFlag('lists', 'read') && <Route path="/blocklists" component={BlockListWrapper} />}
              <Route path="/webhooks" component={WebhooksWrapper} />
              {getFeatureFlag('bulkVerification', 'read') && (
                <Route path="/bulk-query" component={BulkVerificationWrapper} />
              )}
              <Route path="/demos" component={SDKDemosWrapper} />
              <Route
                exact
                path="/documentation"
                render={() => {
                  return <RedirectToBureauDocs docPath={docPath} />;
                }}
              />
              <Route exact path="/faqs" component={FAQs} />

              {/* <Route path="/billing" component={BillingsWrapper} /> */}
              <Route path="*" component={ErrorScreen} />
            </Switch>
          </Suspense>
        </MainContent>
      </Layout>
    </Layout>
  );
};

const RedirectToBureauDocs = ({ docPath }: { docPath?: { url: string } }): ReactElement => {
  const history = useHistory();
  React.useEffect(() => {
    if (docPath) window.open(docPath.url, '_blank');
    history.goBack();
  }, [docPath, history]);

  return (
    <Flex w="full" h="full" alignItems="center" justifyContent="center">
      Redirected to&nbsp;{' '}
      <Link href={docPath?.url} color="blue.500">
        Bureau Docs
      </Link>
    </Flex>
  );
};
