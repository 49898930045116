/* eslint-disable react/display-name */
import React, { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  Box,
  Text,
  ModalCloseButton,
  ModalFooter,
  Flex,
  Button,
  Image,
  Checkbox,
  CheckboxGroup,
  VStack,
} from '@chakra-ui/react';
import { BookOpen } from 'phosphor-react';
import { useRecoilValue } from 'recoil';
import FactsLoader from '@/components/loader/Loader';
import useGetSupplierInputParams from '../queries/useGetSupplierInputParams';
import { ModifyNodeDetails, Inputs } from '../types';
import { ConfigureServicesDescriptions } from '../states/serviceDescriptions';
import WorkflowInputCondition from './WorkflowInputCondition';
import { workflowInput } from '../constants';

export const AddParamsModalHeader = (): ReactElement => {
  return (
    <Box>
      <ModalHeader fontSize="2xl" fontWeight="600" borderColor="gray.300" borderBottomWidth={1}>
        <Box>
          <Text fontSize="md" fontWeight="medium">
            Configure Service
          </Text>
          <Text fontSize="xs" fontWeight="light" color="gray.600">
            Match items between coming from two different sources.{' '}
          </Text>
        </Box>
      </ModalHeader>
      <ModalCloseButton top={4} _focus={{ border: 'none' }} />
    </Box>
  );
};

const AddParamsModal = ({
  onClose,
  nodeDetails,
  onCancel,
}: {
  onClose: (data: Inputs[], action: string) => void;
  nodeDetails: ModifyNodeDetails;
  onCancel: () => void;
}): ReactElement => {
  const nodeId = nodeDetails.oldId.split('node_id')[0];
  const servicesAndParamsDescriptions = useRecoilValue(ConfigureServicesDescriptions);
  const serviceAndParamsDescriptions = servicesAndParamsDescriptions.find(item =>
    item.fields.selectCapability?.fields?.capabilityIDs?.includes(nodeId),
  );
  const { data: inputParams, refetch, isLoading, isRefetching } = useGetSupplierInputParams(nodeId, false);

  const [inputsArray, setInputsArray] = useState<Inputs[]>(() =>
    nodeDetails?.inputs?.length ? nodeDetails.inputs : [],
  );

  const checkedItems = useMemo(() => inputsArray.flatMap(input => !input.isOptional), [inputsArray]);

  const allChecked = useMemo(() => {
    const isConditionalParamsAvailable = inputParams?.inputParameters?.some(
      input => input?.optionality === 'CONDITIONAL',
    );

    return (
      (isConditionalParamsAvailable
        ? inputParams?.inputParameters?.every((input, index) =>
            input?.optionality === 'CONDITIONAL' ? checkedItems[index] : true,
          )
        : checkedItems.every(Boolean)) ?? false
    );
  }, [inputParams, checkedItems]);

  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

  useEffect(() => {
    if (nodeDetails) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeDetails]);

  useEffect(() => {
    if (!nodeDetails?.inputs && inputParams) {
      const tempData: Inputs[] = [];
      if (inputParams?.inputParameters === null) setInputsArray([]);
      inputParams?.inputParameters?.forEach(item => {
        tempData.push({
          ...workflowInput,
          ...item,
          isOptional: item.optionality === 'OPTIONAL',
        });
      });
      setInputsArray(tempData);
    } else if (nodeDetails?.inputs) setInputsArray(nodeDetails.inputs);
  }, [inputParams, nodeDetails]);

  const onAllFieldsCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const isChecked = event.target.checked;
    setInputsArray(inputArray =>
      inputArray.map((input, index) =>
        input.optionality === 'CONDITIONAL'
          ? {
              ...input,
              isOptional: !isChecked,
            }
          : input,
      ),
    );
  };

  const onConditionalFieldCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    inputParam: Inputs,
    index: number,
  ): void => {
    const isChecked = event.target.checked;
    setInputsArray(inputArray =>
      inputArray.map((inp, i) =>
        i === index && inputParam.optionality === 'CONDITIONAL' ? { ...inp, isOptional: !isChecked } : inp,
      ),
    );
  };

  return (
    <Modal isOpen onClose={onCancel} isCentered>
      <ModalOverlay bg="transparentGray.100" />
      <ModalContent height="fit-content" overflow="hidden" borderRadius="lg" minW={1200} h={657}>
        {isLoading || isRefetching ? (
          <FactsLoader />
        ) : (
          <Flex w="full" h="full">
            <Flex direction="column" w={400} borderRightWidth={1} borderColor="gray.200" h="full">
              <Box bg="white.100" p={6} h="fit-content" id="service_header">
                <Text fontSize="xl" fontWeight="medium">
                  {nodeDetails?.oldName}
                </Text>
                <Text fontWeight="normal" fontSize="sm" color="gray.600" pt={2}>
                  {serviceAndParamsDescriptions?.fields?.selectCapability?.fields?.capabilityDescription}
                </Text>
              </Box>
              <Box
                p={6}
                h={`calc(100% - ${(document.getElementById('service_header')?.clientHeight || 0) + 70}px)`}
                overflow="auto"
              >
                <Text fontWeight="normal" fontSize="xs">
                  Body Params
                </Text>
                {inputParams?.inputParameters?.map(item => (
                  <Box bg="white.50" key={item?.key} p={2} borderRadius="md" mt={2}>
                    <Flex alignItems="center">
                      <Text fontWeight="medium" fontSize="sm">
                        {item?.key}
                      </Text>
                      <Text fontWeight="normal" fontSize="xs" color="gray.600" pl={2}>
                        {item?.type?.toLowerCase()}
                      </Text>
                    </Flex>
                    <Text fontSize="xs" fontWeight="normal" pt={2} color="gray.600">
                      {item?.displayName}
                    </Text>
                  </Box>
                ))}
              </Box>
              <a href="https://docs.bureau.id/docs" target="_blank" rel="noopener noreferrer">
                <Flex color="blue.500" ml={6} mb={6} position="absolute" bottom={0} cursor="pointer" zIndex={1}>
                  <BookOpen />
                  <Text fontWeight="normal" fontSize="xs" pl={2}>
                    Read Full Documentation
                  </Text>
                </Flex>
              </a>
            </Flex>
            <Box w={800}>
              <AddParamsModalHeader />
              <ModalBody color="gray.600" p={0} h="75%" overflow="auto">
                {inputParams?.inputParameters ? (
                  <Box w="full" bg="white" p="5">
                    <Checkbox
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                      isDisabled={inputParams.inputParameters?.every(input => input.optionality !== 'CONDITIONAL')}
                      onChange={event => onAllFieldsCheckboxChange(event)}
                    >
                      <Text fontSize="xs" fontWeight="light" color="gray.900">
                        All Fields Required
                      </Text>
                    </Checkbox>
                    {inputParams?.inputParameters?.map((input, index) => (
                      <CheckboxGroup key={input.key}>
                        <VStack alignItems="start">
                          <Flex>
                            <Checkbox
                              isChecked={
                                input.optionality === 'CONDITIONAL'
                                  ? checkedItems[index]
                                  : input.optionality === 'REQUIRED' || !input.optionality // consider optionality === undefined as 'REQUIRED'
                              }
                              isDisabled={!input.optionality || input.optionality !== 'CONDITIONAL'}
                              onChange={event => onConditionalFieldCheckboxChange(event, input, index)}
                            />
                            <Box>
                              <WorkflowInputCondition
                                onValueUpdate={(inputCondition, position) => {
                                  const tempData = [...inputsArray];
                                  tempData[position] = inputCondition;
                                  tempData[position].namespace = nodeDetails.oldId;
                                  setInputsArray(tempData);
                                }}
                                inputItem={inputsArray?.[index]}
                                index={index}
                                key={input.key}
                              />

                              {/* <Flex fontSize="xs" fontWeight="normal" color="gray.600" pl={4}>
                                <Text pr={2} color="gray.800">{`{${input.key}}`}</Text>will be matched with
                                <Text px={2} color="gray.800">{`{Source}`}</Text> in the current capability.
                              </Flex> */}
                            </Box>
                          </Flex>
                        </VStack>
                      </CheckboxGroup>
                    ))}
                  </Box>
                ) : (
                  <Flex w="full" h="full" alignItems="center" justifyContent="center">
                    <Flex direction="column" alignItems="center">
                      <Image src="/assets/icons/emptyConfigure.svg" />
                      <Text fontSize="md" fontWeight="medium" color="gray.800" pt={6}>
                        This service can’t be configured
                      </Text>
                      <Text fontSize="sm" fontWeight="normal" color="gray.600" pt={2}>
                        Configurable service values will be shown here.
                      </Text>
                    </Flex>
                  </Flex>
                )}
              </ModalBody>
              <ModalFooter bg="white" borderTopWidth={1} borderColor="gray.300">
                <Flex justifyContent="flex-end" alignItems="center" bg="white" w="full" py={2}>
                  <Button
                    px={6}
                    bg="purple.300"
                    color="blue.500"
                    fontSize="sm"
                    fontWeight="light"
                    borderRadius="lg"
                    _hover={{ opacity: '0.85' }}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    mx={2}
                    bg="blue.500"
                    color="white"
                    fontSize="sm"
                    fontWeight="light"
                    borderRadius="lg"
                    isDisabled={inputsArray.some(input => input.locationKind && !input.locationType)}
                    _hover={{ opacity: '0.85' }}
                    _disabled={{ bg: 'gray.100', color: 'gray.600' }}
                    onClick={() => onClose(inputsArray, 'edit')}
                  >
                    Add & Save
                  </Button>
                </Flex>
              </ModalFooter>
            </Box>
          </Flex>
        )}
      </ModalContent>
    </Modal>
  );
};

export default AddParamsModal;
