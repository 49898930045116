import React from 'react';
import { IconButton, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { Gear, ListBullets } from 'phosphor-react';
import { DropdownItem } from './DropdownItem';

const WorkflowsSettingsButton = (): React.ReactElement => {
  const history = useHistory();
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Workflow Settings"
        bg="white.50"
        color="blue.700"
        borderColor="gray.200"
        borderWidth={1}
        icon={<Gear />}
      />
      <MenuList px="3" py="4" gridGap="2">
        <DropdownItem title="Lists" leftIcon={<ListBullets />} onClick={() => history.push('/blocklists')} />
      </MenuList>
    </Menu>
  );
};

export default WorkflowsSettingsButton;
