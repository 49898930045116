export interface ParamTypes {
  id: string;
}

export interface SortField {
  field: string;
  order: string;
}

export enum CustomErrorCode {
  RECORD_EXISTS = 422,
  NO_RECORD_FOUND = 422,
  ALREADY_INVITED = 409,
}

export enum UserPermissions {
  CREATE_CREDENTIAL = 'create:credential',
  CREATE_INVITATION = 'create:invitation',
  CREATE_MEMBER = 'create:member',
  DELETE_CREDENTIAL = 'delete:credential',
  DELETE_MEMBER = 'delete:member',
  DELETE_INVITATION = 'delete:invitation',
  DELETE_USER = 'delete:user',
  READ_CREDENTIAL = 'read:credential',
  READ_INVITATION = 'read:invitation',
  READ_MEMBER = 'read:member',
  READ_ROLE_MEMBER = 'read_role:member',
  UPDATE_CREDENTIAL = 'update:credential',
  UPDATE_MEMBER = 'update:member',
  ASSIGN_ROLE = 'assign_role:member',
}

export interface GeneratedKeys {
  secret: string;
  userId: string;
  credentialId: string;
  merchantId: string;
}

export interface OnboardingList {
  createdAt: string;
  credentialId: string;
  tenancy: string;
  updatedAt: string;
  userId: string;
  appName: string;
  secret: string;
}

export interface UserMetaData {
  isWorkflowCreated?: boolean;
  isTransactionReportVisited?: boolean;
  isDashboardVisited?: boolean;
}

export interface AuthDetails {
  email: string;
  emailVerified: boolean;
  sub: string;
  familyName: string;
  givenName: string;
  locale: string;
  name: string;
  nickname: string;
  picture: string;
  orgId: string;
  geography: string;
  permissions?: string[];
  customerId?: {
    lago?: string;
    stripe?: string;
  };
  feature_flag: FeatureFlags;
  is_super_admin: boolean;
  metadata: UserMetaData;
  region: string;
}

export interface FeatureFlags {
  workflowVersion: WorkflowVersion;
  workflows: FeatureFlagDetail;
  entities: FeatureFlagDetail;
  lists: FeatureFlagDetail;
  bulkVerification: FeatureFlagDetail;
  customCapabilities: FeatureFlagDetail;
}

export interface FeatureFlagDetail {
  delete: boolean;
  read: boolean;
  write: boolean;
}

export interface WorkflowVersion {
  isWorkflowV2: boolean;
}

export type GetFeatureFlag = (
  page: keyof FeatureFlags,
  access: keyof FeatureFlagDetail | keyof WorkflowVersion,
) => boolean;

export interface UseFeatureFlag {
  featureFlag: FeatureFlags;
  getFeatureFlag: GetFeatureFlag;
  isLoading: boolean;
}

export const statusValues = [
  { label: 'Active', value: 'true' },
  { label: 'Inactive', value: 'false' },
];

export enum UserAction {
  EDIT = 'edit',
  DELETE = 'delete',
  INVITE = 'invite',
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
  DUPLICATE = 'duplicate',
  ADD = 'add',
  TRYOUT = 'tryout',
  EXPORT = 'export',
}
