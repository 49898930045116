import React, { useEffect, ReactElement, useState } from 'react';
import { useTable, useSortBy, Row, useRowState, useRowSelect, usePagination } from 'react-table';
import ReactPaginate from 'react-paginate';
import { CaretDown, CaretUp } from 'phosphor-react';
import { Table, Tbody, Flex, Text, Box, BoxProps } from '@chakra-ui/react';
import { OptionType, SelectInput } from '@bureau/components';
import PageError from '@/components/page-error/PageError';
import { pageLimits } from '@/constants/constants';
import { colors } from '@/utils/colors';
import { SelectComponentsProps } from 'react-select/src/Select';
import { PhosphorIcon } from '@/screens/entity-list/devices/PhosphorIcon';
import { SortBy, SortOrder } from '@/screens/bulk-verification/types';
import { PaginatedTableProps } from './utils/types';
import './styles.css';
import FactsLoader from '../loader/Loader';
import { TableHeaders } from './table-components/TableHeaders';
import { CheckboxHeader, CheckboxCell } from './CheckboxHeader';
import { TableRow } from './table-components/TableRow';
import { EmptyTableBody } from './EmptyTableBody';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Option = (props: any): React.ReactElement => {
  const { children, innerProps } = props;
  return (
    <Flex bg="white" p={2} alignItems="center" cursor="pointer" _hover={{ bg: 'white.50' }} {...innerProps}>
      <Flex>
        <Text color="gray.900" fontWeight="light">
          {children}
        </Text>
      </Flex>
    </Flex>
  );
};

const DropdownIndicator = (props: { selectProps: SelectComponentsProps }): React.ReactElement => {
  const { selectProps } = props;
  if (selectProps.menuIsOpen) {
    return (
      <Box mr={2}>
        <PhosphorIcon icon={CaretUp} size={16} fontWeight="regular" color={colors.gray[600]} />
      </Box>
    );
  }
  return (
    <Box mr={2}>
      <PhosphorIcon icon={CaretDown} size={16} fontWeight="regular" color={colors.gray[600]} />
    </Box>
  );
};

const PaginatedTable = ({
  data,
  columns,
  onCellClick,
  onRowClick,
  error,
  isLoading,
  setSortField,
  title,
  customEmptyTableBody,
  showCustomEmptyTableBody,
  setSelectedList,
  deviceDataCount = 0,
  isManualSort = true,
  setOffset,
  offset = 0,
  limit = '0',
  setLimit,
  count = 0,
  hidePagination = false,
  fontSize = 'sm',
  isDeviceDashboard = false,
  hiddenColumns = [],
  height = '60vh',
  ...props
}: PaginatedTableProps & BoxProps): React.ReactElement => {
  const customStyles = {
    control: () => ({
      width: '56px',
      borderRadius: '6px',
      fontWeight: 300,
    }),
  };
  const [totalPages, setTotalPages] = useState(0);
  const [, setPageOffset] = useState('');
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      manualSortBy: isManualSort,
      initialState: {
        hiddenColumns,
      },
    },
    useSortBy,
    usePagination,
    useRowState,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(cols => {
        return [
          ...(setSelectedList
            ? [
                {
                  id: 'selection',
                  Header: CheckboxHeader,
                  Cell: CheckboxCell,
                },
              ]
            : []),
          ...columns,
        ];
      });
    },
  );

  useEffect(() => {
    if (!isLoading) setTotalPages(Math.ceil((count || 0) / +limit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, count]);

  useEffect(() => {
    if (setSelectedList) setSelectedList(selectedFlatRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows.length]);

  useEffect(() => {
    if (sortBy.length && setSortField) {
      // For Bulk Query: Sort logic changed at Backend
      setSortField(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        title?.toLowerCase().includes('bulk')
          ? ({
              sortBy: sortBy[0].id as SortBy,
              sortOrder: (sortBy[0].desc ? '-1' : '1') as SortOrder,
            } as { sortBy: SortBy; sortOrder: SortOrder })
          : ({
              field: sortBy[0].id,
              order: sortBy[0].desc ? 'desc' : 'asc',
            } as { field: string; order: string }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  useEffect(() => {
    setPageOffset((offset + 1).toString());
  }, [offset]);

  const RenderBody = (): ReactElement => {
    if (isLoading) {
      return <FactsLoader />;
    }
    return (
      <Box w="full" pr="1px" h={height} overflowY="auto">
        <Table
          borderTop="0px"
          borderTopWidth="0px"
          {...getTableProps()}
          fontSize={fontSize}
          fontWeight="300"
          color="gray.700"
          maxH="100%"
          {...props}
        >
          <TableHeaders headerGroups={headerGroups} />
          <Tbody {...getTableBodyProps()} h="100%" position="relative">
            {data?.length > 0 &&
              rows.map((row: Row, index) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  <TableRow
                    row={row}
                    key={rowProps.key}
                    onCellClick={onCellClick}
                    rowIndex={index}
                    onRowClick={onRowClick}
                    fontSize={fontSize}
                  />
                );
              })}
          </Tbody>
        </Table>
        {!data?.length && (
          <EmptyTableBody
            title={title}
            showCustomEmptyTableBody={showCustomEmptyTableBody}
            customEmptyTableBody={customEmptyTableBody}
          />
        )}
      </Box>
    );
  };

  // const onPageNumberChange = (): void => {
  //   if (setOffset) {
  //     if (+pageOffset <= 0 || +pageOffset > totalPages) {
  //       setOffset(0);
  //       setPageOffset('1');
  //     } else setOffset(+pageOffset - 1);
  //   }
  // };

  if (error) {
    return <PageError />;
  }

  return (
    <Flex direction="column" overflow="auto" h="full" borderTopRadius="lg">
      <RenderBody />
      {!hidePagination && (data.length || isLoading) && (
        <Flex mt={6} mb={8} alignItems="center" justifyContent="space-between" wrap="wrap">
          <Box fontSize="xs" fontWeight="normal" color="gray.500" ml={6}>
            Showing {limit} items per page
          </Box>
          <Flex alignItems="center" pl={6}>
            <Flex fontSize="xs" fontWeight="normal" color="gray.700" alignItems="center" mr={6}>
              {/* <Box mr={2} color="gray.700" fontWeight="normal">
                Show:
              </Box> */}
              {isDeviceDashboard ? (
                <></>
              ) : (
                <SelectInput
                  onChange={pageLimit => {
                    setLimit && setLimit((pageLimit as OptionType)?.value);
                  }}
                  value={limit}
                  options={pageLimits}
                  size="small"
                  isClearable={false}
                  styles={customStyles}
                  components={{
                    DropdownIndicator,
                    Option,
                  }}
                  isSearchable={false}
                />
              )}
            </Flex>
            <ReactPaginate
              previousLabel={isDeviceDashboard ? 'Previous' : ''}
              nextLabel={isDeviceDashboard ? 'Next' : ''}
              pageCount={totalPages}
              onPageChange={(pageNum: { selected: number }) => setOffset && setOffset(pageNum.selected)}
              onClick={e => {
                const { isNext, isPrevious } = e;
                if (!isDeviceDashboard) {
                  return;
                }
                const isNextDisabled = isNext && deviceDataCount < 10;
                const isPrevDisabled = isPrevious && offset === 0;
                if (isNextDisabled || isPrevDisabled) {
                  return;
                }
                if (isNext) {
                  setOffset && setOffset(offset + 10);
                }
                if (isPrevious) {
                  setOffset && setOffset(offset - 10);
                }
              }}
              containerClassName="pagination"
              previousLinkClassName={!isDeviceDashboard ? 'pagination-link' : ''}
              nextLinkClassName={!isDeviceDashboard ? 'pagination-link' : ''}
              disabledClassName="pagination-link-disabled"
              activeClassName="pagination-link-active"
              forcePage={offset}
              marginPagesDisplayed={1}
            />

            {/* <Flex alignItems="center" fontSize="xs" fontWeight="light">
              <Input
                type="number"
                fontSize="xs"
                p="11px"
                value={pageOffset}
                w={`${pageOffset.length === 1 ? 32 : 32 + 6.5 * pageOffset.length}px`}
                h={8}
                mr={2}
                color="blue.500"
                onKeyPress={e => {
                  if (e.key === 'Enter') onPageNumberChange();
                }}
                onChange={e => {
                  setPageOffset(e?.target?.value);
                }}
                onBlur={onPageNumberChange}
              />
              <Box color="gray.500">/</Box>
              <Box color="gray.700" mr={6} fontWeight="light">
                {totalPages}
              </Box>
            </Flex>  */}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default PaginatedTable;
