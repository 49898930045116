import React from 'react';
import { Button, Divider } from '@chakra-ui/react';
import { HeaderWithTabs, Header, Heading, RHSContent, ProfileDropdown } from '@/layout/Header';
import CustomTabs from '@/components/tabs/CustomTabs';
import { ListBullets, Plus } from 'phosphor-react';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useResetRecoilState } from 'recoil';
import WorkflowList from './WorkflowList';
import { BranchAtom } from './states/branchAtom';
import { ConditionsAtom } from './states/conditionsAtom';
import { EndnodeAtom } from './states/endnodeAtom';
import { SelectedNodeAtom } from './states/selectedNodeAtom';
import { WorkflowAtom } from './states/workflowAtom';
import { WorkflowNameAtom } from './states/workflowName';

const workflowTabList = [
  {
    label: 'Templates',
    id: 'workflows/templates',
    content: <WorkflowList workflowType="templates" />,
  },
  {
    label: 'My Workflows',
    id: 'workflows/my-workflows',
    content: <WorkflowList />,
  },
];

const WorkflowsTabs = (): React.ReactElement => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const resetWorkflow = useResetRecoilState(WorkflowAtom);
  const resetBranch = useResetRecoilState(BranchAtom);
  const resetConditions = useResetRecoilState(ConditionsAtom);
  const resetEndNode = useResetRecoilState(EndnodeAtom);
  const resetSelectedNodeItem = useResetRecoilState(SelectedNodeAtom);
  const resetWorkflowName = useResetRecoilState(WorkflowNameAtom);

  const onCreateWorkflow = (): void => {
    resetWorkflow();
    resetBranch();
    resetConditions();
    resetEndNode();
    resetSelectedNodeItem();
    resetWorkflowName();
    queryClient.removeQueries({ queryKey: 'capabilityList', exact: true });
    history.push(`/workflows/create`);
  };
  return (
    <HeaderWithTabs>
      <Header>
        <Heading>
          <Heading.Title>Workflows</Heading.Title>
          {/* <Heading.SubHeader>&#40; {count} Items &#41;</Heading.SubHeader> */}
        </Heading>
        <RHSContent>
          <RHSContent.Buttons>
            <Button
              leftIcon={<ListBullets />}
              backgroundColor="gray.200"
              borderRadius="lg"
              fontSize="sm"
              fontWeight="light"
              onClick={() => history.push('/blocklists')}
              id="create-workflow"
            >
              Lists
            </Button>
            <Button
              leftIcon={<Plus />}
              bg="purple.300"
              fontSize="sm"
              fontWeight="light"
              color="blue.500"
              borderRadius="lg"
              lineHeight="normal"
              onClick={onCreateWorkflow}
              id="create-workflow"
            >
              New Workflow
            </Button>
          </RHSContent.Buttons>
          <Divider orientation="vertical" h="10" />
          <ProfileDropdown />
        </RHSContent>
      </Header>
      <CustomTabs tabs={workflowTabList} />
    </HeaderWithTabs>
  );
};

export default WorkflowsTabs;
