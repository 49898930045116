import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Grid,
  Box,
  Text,
  Flex,
  Divider,
  Tag,
} from '@chakra-ui/react';
import { VStack } from '@chakra-ui/layout';
import ComingSoonTag from '@/components/coming-soon/ComingSoonTag';
import FactsLoader from '@/components/loader/Loader';
import { useHistory } from 'react-router-dom';
import { useCapabilityFilters } from '../user-onboarding/queries/useCapabilityFilters';
import { CapabilityFilterItem } from '../user-onboarding/types';
import { initialUseCaseValues } from './Workflow';
import WorkflowLoader from './components/WorkflowLoader';

const WorkflowOnboardingModal = ({
  setSelectedUseCase,
}: {
  setSelectedUseCase: Dispatch<SetStateAction<CapabilityFilterItem>>;
}): React.ReactElement => {
  const { data: capabilityFilters, isLoading: isFiltersListLoading } = useCapabilityFilters();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [tempUseCaseSelection, setTempUseCaseSelection] = useState<CapabilityFilterItem>(initialUseCaseValues);
  const handleUseCaseSelect = (useCase: CapabilityFilterItem): void => {
    if (tempUseCaseSelection.value === useCase.value) {
      setTempUseCaseSelection(initialUseCaseValues);
    } else {
      setTempUseCaseSelection({ ...useCase, value: useCase.value });
    }
  };
  const onCreateWorkflow = (): void => {
    if (tempUseCaseSelection) {
      setIsLoading(true);
      setSelectedUseCase(tempUseCaseSelection);
      setTimeout(() => {
        onClose();
        setIsLoading(false);
      }, 2000);
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false} isCentered>
      <ModalOverlay sx={{ backdropFilter: 'blur(6px)' }} />
      <ModalContent minW="641" h="calc(100vh - 100px)">
        {isFiltersListLoading ? (
          <FactsLoader />
        ) : (
          <ModalBody p="0" h="full">
            {isLoading ? (
              <Flex h="calc(100vh - 100px)" alignItems="center" justifyContent="center">
                <WorkflowLoader
                  text="Hold tight! We’re setting best experience for you. It will take a few seconds to process"
                  textStyles={{ w: '96' }}
                />
              </Flex>
            ) : (
              <Grid gridTemplateColumns="3fr 0fr 5fr" h="full">
                <Flex
                  px="4"
                  py="6"
                  direction="column"
                  justifyContent="space-between"
                  bg="gray.50"
                  borderLeftRadius="lg"
                >
                  <VStack spacing="2" alignItems="flex-start">
                    <Text
                      fontSize="xl"
                      fontWeight="500"
                      color="gray.800"
                      lineHeight="normal"
                      sx={{ textWrap: 'balance' }}
                    >
                      Create your Workflow
                    </Text>
                    <Text fontSize="xs" fontWeight="300" color="gray.600">
                      Let&apos;s get you started.
                    </Text>
                  </VStack>
                </Flex>
                <Divider orientation="vertical" />
                <Flex direction="column" justifyContent="space-between" position="relative">
                  <Box p="6">
                    <VStack spacing="2" mb="6">
                      <Text fontWeight="medium" lineHeight="130%">
                        Which digital lifecycle stage do you want to secure?
                      </Text>
                      <Text fontSize="xs" fontWeight="300" color="gray.600" lineHeight="normal">
                        Selecting your lifecycle stage helps us customize your experience.
                      </Text>
                    </VStack>
                    <VStack spacing="4" alignItems="unset" maxH="calc(100vh - 324px)" overflow="auto" pr="1">
                      {capabilityFilters?.useCases?.map(useCase => (
                        <UseCaseCard
                          key={useCase.value}
                          useCase={useCase}
                          onClick={handleUseCaseSelect}
                          selectedUseCase={tempUseCaseSelection}
                        />
                      ))}
                    </VStack>
                  </Box>
                  <ModalFooter
                    gridGap="4"
                    p="4"
                    borderTop="1px"
                    borderColor="inherit"
                    position="absolute"
                    bottom="0"
                    left="0"
                    right="0"
                    background="white"
                    borderBottomRightRadius="lg"
                  >
                    <Button
                      borderRadius="lg"
                      fontSize="sm"
                      fontWeight="normal"
                      borderWidth={1}
                      borderColor="gray.200"
                      bg="white.50"
                      onClick={() => history.replace('/workflows/my-workflow')}
                    >
                      Cancel
                    </Button>
                    <Button
                      fontSize="sm"
                      fontWeight="300"
                      colorScheme="blue"
                      onClick={onCreateWorkflow}
                      isLoading={isLoading}
                      isDisabled={!tempUseCaseSelection.value}
                    >
                      Create Workflow
                    </Button>
                  </ModalFooter>
                </Flex>
              </Grid>
            )}
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};
const UseCaseCard = ({
  useCase: { displayText, description, tags, comingSoon, value },
  useCase,
  onClick,
  selectedUseCase,
}: {
  useCase: CapabilityFilterItem;
  onClick: Function;
  selectedUseCase: CapabilityFilterItem;
}): ReactElement => {
  return (
    <Flex
      direction="column"
      gridGap="2"
      p="3"
      borderWidth={1}
      borderRadius="lg"
      borderColor={selectedUseCase.value === value ? 'gray.800' : 'gray.200'}
      opacity={comingSoon ? 0.5 : 1}
      cursor={comingSoon ? 'default' : 'pointer'}
      bg={selectedUseCase.value === value ? 'white.100' : 'white'}
      onClick={() => {
        if (!comingSoon) onClick(useCase);
      }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="sm" lineHeight="normal">
          {displayText}
        </Text>
        {comingSoon && <ComingSoonTag size="sm" />}
      </Flex>
      <Text fontSize="xs" fontWeight="light" lineHeight="160%" color="gray.600">
        {description}
      </Text>
      {tags?.length ? (
        <Flex wrap="wrap" gridRowGap="2" gridColumnGap="2">
          {tags?.map(tag => (
            <Tag
              key={tag}
              py="1"
              borderWidth={1}
              borderRadius="lg"
              bg="white.50"
              borderColor="gray.200"
              fontSize="10"
              fontWeight="300"
            >
              {tag}
            </Tag>
          ))}
        </Flex>
      ) : (
        <></>
      )}
    </Flex>
  );
};
export default WorkflowOnboardingModal;
