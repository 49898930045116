import React, { ReactElement } from 'react';
import { Button, ButtonProps, useClipboard } from '@chakra-ui/react';
import { MdContentCopy } from 'react-icons/md';

const CopyButton = ({ text, ...rest }: ButtonProps & { text: string }): ReactElement => {
  const { onCopy } = useClipboard(text);
  return (
    <Button
      display="flex"
      alignItems="center"
      height="16px"
      borderRadius="sm"
      minWidth="16px"
      padding="0"
      pb="1"
      aria-label="copy"
      variant="ghost"
      onClick={e => {
        e.stopPropagation();
        onCopy();
      }}
      {...rest}
    >
      <MdContentCopy color="#BCC2D4" />
    </Button>
  );
};
export default CopyButton;
