import React, { ReactElement } from 'react';
import { Flex, Heading, Image, Button } from '@chakra-ui/react';
import { Link as LinkIcon } from 'phosphor-react';
import { formatDate } from '@/utils/utils';
import { bannerHeight } from '@/constants/constants';
import useGetSubscription from './queries/useGetSubscriptio';

export const TrialBanner = (): ReactElement => {
  const { data } = useGetSubscription();

  //! stripe check removed because we are not redirecting to billing page and billing is not being used s
  // const checkStripeKey = (authenticationDetails: AuthDetails): boolean => {
  //   return !(authenticationDetails?.customerId && authenticationDetails?.customerId.stripe);
  // };

  // if (checkStripeKey(meDetails || authDetails)) {
  //   return <></>;
  // }

  return (
    <Flex align="center" justify="center" h={`${bannerHeight}px`} w="full" bg="#262951">
      <Image src="/assets/Star.svg" w={10} h={7} />
      <Heading color="white" textAlign="center" fontSize="sm" fontWeight="normal">
        You are currently in a trial till {formatDate(data?.endTimestamp)} and {data?.balanceLeft} calls remaining.
      </Heading>
      <Button
        as="a"
        target="_blank"
        variant="link"
        href="https://www.bureau.id/contact-us"
        color="purple.200"
        ml="2"
        fontSize="sm"
        fontWeight="medium"
        rightIcon={<LinkIcon size={18} />}
        _focus={{ boxShadow: 'none' }}
      >
        Contact Us
      </Button>
    </Flex>
  );
};
