import React from 'react';
import { Button, Divider, Flex, Image, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

import { Header, Heading, RHSContent, ProfileDropdown } from '@/layout/Header';
import { Plus } from 'phosphor-react';
import WorkflowsSettingsButton from '@/components/WorkflowsSettingsButton';
import CopyPasteJsonModal from '@/screens/capabilitiesV2/workflow-creation/components/workflow-import/CopyPasteJsonModal';
import ImportFileModal from '@/screens/capabilitiesV2/workflow-creation/components/workflow-import/ImportFileModal';
import { CreateWorkflowRequest } from '../workflow-creation/types';

const WorkFlowListHeader = ({
  count,
  isLoading,
  showImportFilePopup,
  nameExistsError,
  setNameExistsError,
  setShowImportFilePopup,
  showCopyPasteJsonPopup,
  setShowCopyPasteJsonPopup,
  onCreateWorkflow,
  mutateAsync,
  isButtonLoading,
  apiError,
  setApiError,
}: {
  count: number;
  isLoading: boolean;
  showImportFilePopup: boolean;
  nameExistsError: boolean;
  setNameExistsError: (val: boolean) => void;
  showCopyPasteJsonPopup: boolean;
  setShowCopyPasteJsonPopup: (val: boolean) => void;
  setShowImportFilePopup: (val: boolean) => void;
  onCreateWorkflow: () => void;
  mutateAsync: (data: CreateWorkflowRequest) => void;
  isButtonLoading: boolean;
  apiError: boolean;
  setApiError: (val: boolean) => void;
}): React.ReactElement => {
  return (
    <Header>
      <Heading>
        <Heading.Title>Workflows</Heading.Title>
        {/* <Heading.SubHeader>&#40; {count} Items &#41;</Heading.SubHeader> */}
      </Heading>
      <RHSContent>
        <RHSContent.Buttons>
          <Flex>
            <Menu>
              <MenuButton
                d="none" // ! Display NONE
                as={Button}
                bg="white.50"
                color="purple.100"
                borderColor="blueGray.100"
                fontWeight="light"
                fontSize="sm"
                borderWidth={1}
              >
                <Flex justifyContent="center">
                  <Image boxSize={5} src="/assets/icons/import.svg" mr={2} />
                  Import
                </Flex>
              </MenuButton>
              <MenuList>
                <MenuItem
                  fontWeight={300}
                  fontSize="sm"
                  onClick={() => {
                    setNameExistsError(false);
                    setShowImportFilePopup(true);
                  }}
                >
                  Import a file
                </MenuItem>
                <MenuItem fontWeight={300} fontSize="sm" onClick={() => setShowCopyPasteJsonPopup(true)}>
                  Copy/Paste JSON
                </MenuItem>
              </MenuList>
            </Menu>
            <CopyPasteJsonModal
              onClose={(workflowName: string, workflowJson: string) => {
                mutateAsync({
                  name: workflowName,
                  ...(workflowJson ? { workflowObject: JSON.parse(workflowJson) } : {}),
                });
                setApiError(false);
              }}
              onCancel={() => setShowCopyPasteJsonPopup(false)}
              nameExistError={nameExistsError}
              apiError={apiError}
              setNameExistError={setNameExistsError}
              showCopyPasteJsonPopup={showCopyPasteJsonPopup}
            />
            {showImportFilePopup && (
              <ImportFileModal
                onClose={(workflowName: string, workflowJson: string | ArrayBuffer) => {
                  setNameExistsError(false);
                  mutateAsync({
                    name: workflowName,
                    ...(workflowJson ? { workflowObject: JSON.parse(workflowJson as string) } : {}),
                  });
                }}
                onCancel={() => setShowImportFilePopup(false)}
                errorMessage={nameExistsError ? 'A workflow with the given name already exists' : ''}
              />
            )}
          </Flex>
          <Button
            leftIcon={<Plus />}
            bg="purple.300"
            color="blue.500"
            borderRadius="lg"
            fontWeight="light"
            fontSize="sm"
            onClick={onCreateWorkflow}
            id="create-workflow"
          >
            New Workflow
          </Button>
          <WorkflowsSettingsButton />
        </RHSContent.Buttons>
        <Divider orientation="vertical" h="10" />
        <ProfileDropdown />
      </RHSContent>
    </Header>
  );
};

export default WorkFlowListHeader;
