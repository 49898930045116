import React, { Dispatch, SetStateAction, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CaretLeft, CheckCircle, PaperPlaneTilt, Warning } from 'phosphor-react';
import { Flex, Button, Input, useToast, Box, Text, Spinner, Tooltip } from '@chakra-ui/react';
import { colors } from '@/utils/colors';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useCreateWorkflow from '../workflows/workflow-creation/queries/useCreateWorkflow';
import { WorkflowAtom } from './states/workflowAtom';
import useGetWorkflow from './queries/useGetWorkflow';
import useUpdateWorkflow from './queries/useUpdateWorkflow';
import { EndnodeAtom } from './states/endnodeAtom';
import { generateUUID } from './utils';
import { WorkflowNameAtom } from './states/workflowName';
import { parseOutputs } from '../workflows/workflow-creation/helpers';
import useGetInputAttributes from '../workflows/workflow-creation/queries/useGetInputAttributes';
import { CapabilityFilterItem } from '../user-onboarding/types';
import WorkflowPublishModal from './components/WorkflowPublishPopup';
import IntegrationsButton from './Integrations';
import AlertsButton from './Alerts';
import TryoutButton from './tryout/components/TryoutButton';
import { AlertsAtom } from './states/alertsAtom';
import SDKPreviewButton from './SDKPreviewButton';

const WorkflowHeader = ({
  name = '',
  selectedUseCase,
  setShowTryoutModal,
  workflowId,
  setWorkflowId,
  openConfigureModal,
}: {
  name?: string;
  selectedUseCase: CapabilityFilterItem;
  setShowTryoutModal: Dispatch<SetStateAction<boolean>>;
  openConfigureModal: () => void;
  workflowId: string;
  setWorkflowId: Dispatch<SetStateAction<string>>;
}): React.ReactElement => {
  const toast = useToast();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const isTemplate = pathname.includes('templates');
  const { data: workflowData, isFetching: isWorkFlowDataLoading } = useGetWorkflow(id);
  const endColumn = useRecoilValue(EndnodeAtom);
  const workflowName = useRecoilValue(WorkflowNameAtom);
  const setWorkflowName = useSetRecoilState(WorkflowNameAtom);
  const workflow = useRecoilValue(WorkflowAtom);
  const alertsList = useRecoilValue(AlertsAtom);
  const { data: inputAttributes = [] } = useGetInputAttributes();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const { mutateAsync, isLoading: isLoadingWorkflowsave } = useCreateWorkflow({
    onSuccess: data => {
      setShowSuccessPopup(true);
      setWorkflowId(data.workflowId);
    },
    onError: error => {
      toast({
        title: 'Failed to save workflow',
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
    },
  });

  const { mutateAsync: updateWorkflow, isLoading: isLoadingWorkflowUpdate } = useUpdateWorkflow({
    onSuccess: data => {
      toast({
        render: () => <WorkflowPublishToast setShowSuccessPopup={setShowSuccessPopup} isError={false} />,
      });
    },
    onError: error => {
      toast({
        render: () => <WorkflowPublishToast setShowSuccessPopup={setShowSuccessPopup} isError />,
      });
    },
  });

  const handlePublish = (): void => {
    const nodeId = `column_${generateUUID()}`;

    const start_node = {
      id: nodeId,
      metadata: {},
      nodes: [
        {
          columnPosition: 0,
          dependencies: [],
          description: '',
          id: 'start_ghdvng',
          kind: 'START',
          name: 'Input',
          nodePosition: 0,
          outputs: parseOutputs(inputAttributes, 'start_ghdvng'),
        },
      ],
    };
    if (!workflowId) {
      mutateAsync({
        name: workflowName,
        workflowObject: { columns: [start_node, ...workflow, endColumn] },
        releaseType: 'v2',
        useCase: selectedUseCase.value,
      });
      return;
    }
    if (workflowId || id) {
      updateWorkflow({
        id: workflowId || id,
        workflow: {
          workflowObject: { columns: [start_node, ...workflow, endColumn] },
          name: workflowName,
          releaseType: 'v2',
          useCase: workflowData?.useCase,
        },
      });
    }
  };

  const navigateBack = (): void => {
    history.push(isTemplate ? '/workflows/templates' : '/workflows/my-workflows');
  };

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        bg="white"
        minW="fit-content"
        px={6}
        py={4}
        borderWidth="1px"
        borderColor="blue.100"
        borderRadius="56"
        boxShadow="0px 4px 8px rgba(17, 25, 99, 0.04)"
        mx={6}
        mt={4}
        zIndex="0"
      >
        <Flex direction="column" w="full">
          <Flex direction="row" alignItems="center">
            <CaretLeft
              size={20}
              style={{ marginTop: '2px', cursor: 'pointer', color: colors.gray[500] }}
              onClick={navigateBack}
            />
            <Flex alignItems="center" color="gray.800" w="80%">
              {isWorkFlowDataLoading ? (
                <Flex width="265px" justify="center">
                  <Spinner size="sm" color="gray.300" />
                </Flex>
              ) : (
                <Input
                  value={workflowName ?? ''}
                  onChange={evt => {
                    setWorkflowName(evt.target.value);
                  }}
                  fontSize="lg"
                  _hover={{ bg: 'blue.100', cursor: 'pointer' }}
                  _focus={{ outline: 'none' }}
                  textAlign="left"
                  border="none"
                  fontWeight="500"
                  aria-label="Edit Workflow Name"
                  title={workflowName}
                  isDisabled={isTemplate}
                  _disabled={{ opacity: 1 }}
                />
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex flex={1} gridGap="4" justifyContent="flex-end">
          <SDKPreviewButton openConfigureModal={openConfigureModal} workflowId={workflowId || id} />
          <TryoutButton openConfigureModal={openConfigureModal} workflowId={workflowId || id} />
          {!isTemplate && (
            <>
              <AlertsButton openConfigureModal={openConfigureModal} />
              <IntegrationsButton openConfigureModal={openConfigureModal} workflowId={workflowId || id} />
              <Tooltip
                label={
                  <Text fontSize="xs" fontWeight="normal" color="gray.50">
                    {alertsList.length ? 'Resolve Alerts first.' : ''}
                  </Text>
                }
                aria-label="A tooltip for Publish button"
                isDisabled={!alertsList.length}
                hasArrow
                placement="bottom-start"
                openDelay={200}
                closeDelay={300}
                p="3"
                borderRadius="lg"
                bg="gray.800"
                maxW="52"
              >
                {/* Added Box as wrapper to make tooltip work as expected */}
                <Box>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    borderRadius="lg"
                    fontWeight="light"
                    gridGap="2"
                    onClick={handlePublish}
                    id="publish-workflow"
                    isLoading={isLoadingWorkflowUpdate || isLoadingWorkflowsave}
                    isDisabled={!workflow.length || !!alertsList.length}
                  >
                    <PaperPlaneTilt size={18} weight="light" color="white" /> {workflowId || id ? 'Update' : 'Publish'}{' '}
                  </Button>
                </Box>
              </Tooltip>
            </>
          )}
        </Flex>
      </Flex>
      {showSuccessPopup && (
        <WorkflowPublishModal
          onClose={() => {
            setShowSuccessPopup(false);
          }}
          onTryoutClick={() => {
            openConfigureModal();
            setShowSuccessPopup(false);
          }}
          id={id || workflowId}
        />
      )}
    </>
  );
};

const WorkflowPublishToast = ({
  setShowSuccessPopup,
  isError,
}: {
  setShowSuccessPopup: Dispatch<SetStateAction<boolean>>;
  isError: boolean;
}): React.ReactElement => {
  const toast = useToast();

  return (
    <Box color="white" w="fit-content" bg="white" boxShadow="lg" borderRadius="lg" h="56px" pl={5} px={4}>
      <Flex h="full" alignItems="center">
        {isError ? (
          <Warning size={24} color={colors.orange[500]} weight="fill" />
        ) : (
          <CheckCircle size={24} color={colors.green[500]} weight="fill" />
        )}
        <Text fontSize="sm" fontWeight="normal" pl={3} color="gray.700">
          {isError
            ? 'Unable to publish the Workflow. Please try again in some time.'
            : `Workflow has been Updated Successfully`}
        </Text>
        {!isError && (
          <Text
            fontSize="xs"
            cursor="pointer"
            pl={6}
            fontWeight="normal"
            color="blue.900"
            onClick={() => {
              setShowSuccessPopup(true);
              toast.closeAll();
            }}
          >
            View Details
          </Text>
        )}
      </Flex>
    </Box>
  );
};

export default WorkflowHeader;
