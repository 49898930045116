import React, { ReactElement, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Heading,
  Button,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  FormControl,
  Input,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  useToast,
} from '@chakra-ui/react';
import { CopyButton } from '@bureau/components/src';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { CodeSimple, Info } from 'phosphor-react';
import { ToastMessage } from '@/components/copy-button/types';
import FAQAccordion from '@/components/FAQAccordion';
import { billingFAQs } from '@/constants/constants';
import { bureauPlatformApi } from '@/utils/api';
import { getCurl } from '../workflows/components/try-out-modal/helper';
import { WorkflowAtom } from './states/workflowAtom';
import { Parameter } from '../workflows/components/try-out-modal/types';
import { dummyWorkflowObject } from './constant';

export const IntegrationConfig = ({ workflowId }: { workflowId: string }): ReactElement => {
  const organization_id = bureauPlatformApi.defaults.headers.common['X-Bureau-Auth-Org-ID'] || '';
  const history = useHistory();
  return (
    <>
      <Box p="4">
        <Flex flexDirection="column" borderColor="gray.300" mb="4">
          <Flex flexDirection="row" mt="2">
            <Flex
              flex="1"
              bg="gray.50"
              mr="4"
              borderWidth={1}
              borderColor="gray.200"
              borderRadius="md"
              flexDirection="column"
              p="4"
            >
              <Heading fontSize="md" color="gray.800" fontWeight="normal">
                Authentication & Credentials
              </Heading>
              <Text color="gray.600" mt="2" fontSize="sm" fontWeight="light">
                These keys can be used to authenticate the workflow as well as configure the code to send data.
              </Text>

              <Flex flexDirection="column" mt="4">
                <Text color="gray.600" fontSize="xs" fontWeight="light">
                  Workflow ID
                </Text>
                <Flex align="center">
                  <Text color="gray.800" fontWeight="normal" fontSize="sm" mr="2">
                    {workflowId}
                  </Text>
                  <CopyButton text={workflowId} />
                </Flex>
              </Flex>

              <Flex flexDirection="column" mt="4">
                <Text color="gray.600" fontSize="xs" fontWeight="light">
                  Organization ID
                </Text>
                <Flex direction="row" align="center">
                  <Text color="gray.800" fontWeight="normal" fontSize="sm" mr="2">
                    {organization_id}
                  </Text>
                  <CopyButton text={organization_id} />
                </Flex>
              </Flex>

              <Flex mt="8">
                <Button
                  borderRadius="lg"
                  fontWeight="normal"
                  fontSize="sm"
                  bg="gray.200"
                  borderWidth={1}
                  borderColor="gray.200"
                  color="gray.800"
                  id="api-keys"
                  onClick={() => history.push('/admin/api-keys')}
                >
                  Manage API Keys
                </Button>
              </Flex>
            </Flex>
            <Flex flex="1">{/* <h1>video</h1> */}</Flex>
          </Flex>
        </Flex>
        <IntegrationsView id={workflowId} />
      </Box>
    </>
  );
};

const IntegrationsView = ({ id }: { id: string }): ReactElement => {
  const [tabIndex, setTabIndex] = useState(0);
  const workflow = useRecoilValue(WorkflowAtom);
  const workflowObject = workflow.length ? workflow : dummyWorkflowObject;
  const [transactionInputs, setTransactionInputs] = useState<Parameter[]>([]);
  const toast = useToast();

  useEffect(() => {
    const headingDom: HTMLElement | null = document.getElementById('curl-request');
    const inputs = workflowObject
      .flatMap(wrkFlw => wrkFlw.nodes)
      .flatMap(node => node.inputs)
      .map(input => ({ ...input, parameter: input?.key, value: '' }));
    const parameterList = [] as Parameter[];
    inputs.forEach(input => {
      const index = parameterList.findIndex(param => param.parameter === input.parameter);
      const inputList = input as Parameter;
      if (index === -1) {
        parameterList.push(inputList);
      }
    });
    setTransactionInputs(parameterList);
    if (headingDom) headingDom.innerHTML = getCurl(parameterList, id);
  }, [id, workflowObject]);

  useEffect(() => {
    const headingDom: HTMLElement | null = document.getElementById('curl-request');

    if (headingDom) headingDom.innerHTML = getCurl(transactionInputs, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(transactionInputs)]);

  return (
    <form>
      <Flex
        direction="column"
        mt="4"
        pt="4"
        borderWidth={1}
        borderTopColor="gray.200"
        borderLeft="none"
        borderBottom="none"
        borderRight="none"
      >
        <Flex justifyContent="space-between" mx="2">
          <Text fontSize="lg" fontWeight="medium" color="gray.800">
            Integration
          </Text>
          <Button
            variant="link"
            leftIcon={<CodeSimple />}
            mr="8"
            color="blue.400"
            fontSize="sm"
            fontWeight="normal"
            _hover={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}
            onClick={() => {
              navigator.clipboard.writeText(getCurl(transactionInputs, id) as string);
              toast({
                title: ToastMessage.COPIED_TO_CLIPBOARD,
                status: 'info',
                duration: 3000,
                isClosable: true,
              });
            }}
          >
            CURL
          </Button>
        </Flex>

        <Text fontSize="xs" fontWeight="300" color="gray.600" lineHeight="160%">
          You can read more about how to get started in our API documentation.
        </Text>

        <Tabs color="gray.600" variant="unstyled" height="88%" mt="4" onChange={index => setTabIndex(index)}>
          <TabList bg="white">
            <Tab _selected={{ borderBottom: 'none', boxShadow: 'none' }} fontSize="sm" pl={0} pb={0}>
              <Flex direction="column" width="fit-content">
                <Box
                  pb={2}
                  fontWeight={tabIndex === 0 ? 'initial' : 'light'}
                  color={tabIndex === 0 ? 'blue.700' : 'gray.700'}
                >
                  <h1>cURL</h1>
                </Box>
                <Box
                  borderWidth={2}
                  borderRadius="lg"
                  borderColor={tabIndex === 0 ? 'blue.700' : 'transparent'}
                  bg={tabIndex === 0 ? 'blue.700' : 'transparent'}
                />
              </Flex>
            </Tab>
            <Tab _selected={{ borderBottom: 'none', boxShadow: 'none' }} fontSize="sm" pb={0}>
              <Flex direction="column" width="fit-content">
                <Box
                  pb={2}
                  fontWeight={tabIndex === 1 ? 'initial' : 'light'}
                  color={tabIndex === 1 ? 'blue.700' : 'gray.600'}
                >
                  Table View
                </Box>
                <Box
                  borderWidth={2}
                  borderRadius="lg"
                  borderColor={tabIndex === 1 ? 'blue.700' : 'transparent'}
                  bg={tabIndex === 1 ? 'blue.700' : 'transparent'}
                />
              </Flex>
            </Tab>
          </TabList>
          <TabPanels h="calc(100% - 42px)">
            <TabPanel h="100%">
              <Box w="full" h="auto" overflow="auto">
                <pre id="curl-request" className="json-container" />
              </Box>
            </TabPanel>
            <TabPanel h="100%" position="relative">
              <Flex h="100%" w="full" mt="2">
                <TableContainer w="full" p="0">
                  <Table variant="simple">
                    <Thead borderWidth={1} borderColor="gray.50">
                      <Tr>
                        <Th
                          borderRightWidth={1}
                          fontSize="md"
                          fontWeight="normal"
                          color="gray.800"
                          p="4"
                          textTransform="capitalize"
                          bg="gray.200"
                        >
                          Input
                        </Th>
                        <Th
                          borderRightWidth={1}
                          fontSize="md"
                          fontWeight="normal"
                          color="gray.800"
                          p="4"
                          textTransform="capitalize"
                          bg="gray.200"
                        >
                          Add Values
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {transactionInputs.map(tInput => (
                        <Tr borderLeftWidth={1} borderBottomWidth={1} borderRightWidth={1} key={tInput.displayName}>
                          <Td fontSize="sm" fontWeight="normal">
                            {tInput.displayName}
                          </Td>
                          <Flex borderLeftWidth={1} py="3" h="full">
                            <FormControl w="fit-content" ml="2">
                              <Input
                                type="email"
                                name={tInput.parameter}
                                onChange={e => {
                                  const { value } = e.target;
                                  const key = tInput.parameter;
                                  setTransactionInputs(prevInput => {
                                    return prevInput.map(input => {
                                      if (input.parameter === key) {
                                        return {
                                          ...input,
                                          value,
                                        };
                                      }
                                      return input;
                                    });
                                  });
                                }}
                              />
                            </FormControl>
                          </Flex>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <StepProcess id={id} />
      {/* <SampleResponse /> */}
      <IntegrationsFAQ />
    </form>
  );
};

const StepProcess = ({ id }: { id: string }): ReactElement => {
  return (
    <Accordion allowToggle>
      <AccordionItem mt="4" borderWidth={1} borderRadius="md" borderColor="gray.200" bg="gray.50">
        <h2>
          <AccordionButton>
            <Flex as="span" align="center" fontSize="md" flex="1" textAlign="left">
              <Info style={{ marginRight: '2' }} /> Step by step process
            </Flex>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Heading color="gray.800" mt="4" fontSize="md" mb="4">
            Step 1 - Define the HTTP Method and the Endpoint
          </Heading>
          <Text fontSize="sm" d="inline" color="gray.600">
            The
            <Text d="inline" color="blue.400">
              {' '}
              --request POST
            </Text>{' '}
            option tells cURL that we are making a POST request. <br />
            <br />
            The URL that follows is the endpoint we are hitting. <br />
            <br />
            Test Mode :{' '}
            <Text color="blue.600" d="inline">
              https://api.overwatch.stg.bureau.id/
            </Text>{' '}
            <br />
            Live Mode :{' '}
            <Text color="blue.600" d="inline">
              https://api.overwatch.bureau.id/
            </Text>
          </Text>

          <Heading color="gray.800" fontSize="md" mt="8" mb="4">
            Step 2 - Sending JSON Data
          </Heading>

          <Text d="inline" fontSize="sm" color="gray.600">
            The
            <Text d="inline" color="blue.400">
              {' '}
              --data-raw
            </Text>{' '}
            is used to send the JSON body content. Here were sending an object with two properties: <br />
            <br />
            <Text color="orange.500" d="inline">
              workflowId: {id}
            </Text>
            <br />
            <Text color="orange.500" d="inline">
              data:{' {}'}
            </Text>
            <br />
            <br />
            Now, when you run this cURL command, it will initiate a workflow and send a POST request to the API
            endpoint. <br />
            <br />
            Please refer to your platform-specific documentation for exact details on how to interact with the API and
            pass workflow-specific data.
          </Text>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

// const SampleResponse = (): ReactElement => {
//   return (
//     <>
//       <Flex direction="column" mt="8">
//         <Text fontSize="lg" fontWeight="medium" color="gray.800">
//           Sample Response
//         </Text>
//         <Text fontSize="xs" fontWeight="300" color="gray.600" lineHeight="160%">
//           You can read more about how to get started in our API documentation.{' '}
//         </Text>

//         <Flex mt="4" borderWidth={1} bg="gray.50" borderRadius="md" borderColor="gray.200" p="4">
//           <h2>Response goes here!</h2>
//         </Flex>
//       </Flex>
//     </>
//   );
// };

export const IntegrationsFAQ = (): ReactElement => {
  return (
    <>
      <Flex direction="column" bg="white" borderColor="gray.200" borderTopWidth={1} mt="8">
        <Flex direction="column" flex="1" mt="6">
          <Heading color="gray.800" fontSize="lg" fontWeight="medium" textAlign="left">
            FAQs
          </Heading>
        </Flex>

        <Flex w="full" mt="6">
          <FAQAccordion faqs={billingFAQs} styles={{ w: 'full' }} />
        </Flex>
      </Flex>
    </>
  );
};
