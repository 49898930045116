export enum Pages {
  CreateApplication,
  FileUpload,
  Preview,
  BatchScrubStarted,
}

export interface FileResponse {
  code: string;
  description: string;
  referenceID: string;
}

export enum ToastMessage {
  UNSUPPORTED_FILE_MESSAGE = 'Please upload proper file',
  WRONG_NUMBER_FORMAT_MESSAGE = 'Please enter the number in correct format',
  CANCEL_APPLICATION_PROCESSING = 'Cancel Requested',
  ERROR_MESSAGE = 'Some error occurred. Please try after some time.',
  UPLOAD_SUCCESSFUL = 'Upload Successful',
  APPLICATION_RETRY = 'Retry Requested',
  DOWNLOAD_FILE_ERROR = 'Some error occurred while downloading file. Please try after some time',
}

export enum BulkQueryStatusValue {
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
  UPLOADING = 'UPLOADING',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  CANCELLING = 'CANCELLING',
}

export interface BulkVerificationFileResponse {
  objectKey: string;
  objectSize: number;
  rowCount: number;
}

export interface GenerateReportDetails {
  name: string;
  createdAt: number;
  status: string;
  size: number;
  responseType: string;
}

export interface BulkQueryDetails {
  applicationId: string;
  name: string;
  serviceIds: string[];
  requestFile: {
    fileName: string;
  };
  status: string;
  responseSummary: {
    [key: string]: {
      [key: string]: number;
    };
  };
  statusSummary: {
    okCount: number;
    serverErrorCount: number;
    clientErrorCount: number;
    total: number;
  };
  progressDetails: {
    completionPercentage: number;
    perServiceCompletionPercentage: {
      [key: string]: number;
    };
  };
  files: GenerateReportDetails[];
  applicationDetails: {
    type: string;
    id: string;
  };
  createdAt: number;
  completedOn: number;
  totalRows: number;
  rowsProcessed: number;
  createdBy: string;
  itemName: string;
  performRiskAnalysis?: boolean;
  services: ServicesList[];
}

export interface ServicesList {
  serviceId: string;
  displayName: string;
  selectedSignals: string[];
}

export interface BulkVerification {
  applications: Array<BulkVerificationsResponse>;
  totalRecords?: number;
}

export interface BulkVerificationsResponse {
  applicationData: {
    signal: string;
    templateId: string;
    workfflowId: string;
  };
  applicationFor: string;
  applicationId: string;
  createdAt: number;
  fileNames: {
    errorResponseFile: null;
    okResponseFile: null;
    requestFile: string;
  };
  merchantId: string;
  results: {
    processedCount: number;
    responseSummary: null;
  };
  status: string;
}

export type ParamsType = {
  applicationId: string;
};

export interface SignedUrlResponse {
  applicationId: string;
  merchantId: string;
  status: string;
  progressDetails: {
    processedCount: number;
    responseSummary: string | null;
  };
  files: {
    requestFile: {
      objectKey: string;
      objectSize: number;
      rowCount: number;
    };
    okResponseFile: {
      objectKey: string;
      objectSize: number;
      rowCount: number;
    };
    errorResponseFile: {
      objectKey: string;
      objectSize: number;
      rowCount: number;
    };
  };
  applicationDetails: {
    type: string;
    id: string;
  };
  createdAt: number;
  signedURL: string;
  uploadURI: string;
}

export interface SignedUrlRequest {
  applicationType?: string;
  id?: string;
}
export interface CreateApplicationRequest {
  performRiskAnalysis?: boolean;
  name?: string;
  serviceIds?: string[];
  selectedService?: SelectedServices[];
}

export interface CreateApplicationResponse {
  applicationId: string;
  uploadURI: string;
}

export interface UploadFileRequest {
  uploadURI: string;
  blobData: Blob;
  applicationId?: string;
  progressCallback?: (progressEvent: ProgressEvent) => void;
}

export type BulkVerificationList = {
  applications: BulkVerificationsResponse[];
  count?: number;
};

export type DownloadUrlRequest = {
  [key: string]: string;
};

export type ValidateFileResponse = {
  validationError?: 'file too large' | 'empty file' | 'missing columns';
  missingColumns?: string[];
  totalRows?: number;
  fileSize?: number;
};

export type GenerateReportResponse = {
  okResponseFile: string;
  errorResponseFile: string;
};

export interface OutputParmeters {
  name: string;
  displayName: string;
  outputParameters: {
    displayName: string;
    key: string;
  }[];
}

export interface BulkQueryFilterField {
  fileType?: string;
}

export interface BulkQueryDataResponse {
  records: {
    length: string;
    totalWords: string;
  }[];
  count?: number;
  totalRecords?: number;
}

export interface SampleFileData {
  inputParameters: {
    displayName: string;
    key: string;
  }[];
}

export interface Suppliers {
  suppliers: {
    id: string;
    name: string;
    type: 'sync' | 'async';
    asyncDetails: {
      submitId: string;
      reportId: string;
    };
    allowedSignals?: string[];
  }[];
  riskModelServices: string[];
}

export interface SelectedServices {
  signals: string[];
  id: string;
}

export interface FilterFields {
  status?: string[];
  createdAt?: string[];
}

export enum SortBy {
  createdAt = 'createdAt',
  status = 'status',
  applicationName = 'applicationName',
  createdBy = 'createdBy',
}

export enum SortOrder {
  descending = '-1',
  ascending = '1',
}

export interface SortData {
  sortBy: SortBy;
  sortOrder: SortOrder;
}

export interface CardData {
  title: string;
  value?: string;
}
