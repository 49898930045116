import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { CaretLeft, Play, RocketLaunch } from 'phosphor-react';
import { Flex, Box, Button, useToast, Text, Input, Image, Switch, FormControl, FormLabel } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { CustomErrorCode } from '@/types/common';
import CopyButton from '@/components/copy-button/CopyButton';
import { colors } from '@/utils/colors';
import AlertMessageModal from '@/components/AlertMessageModal';
import useUpdateWorkflow from '../queries/useUpdateWorkflow';
import { WorkflowModified } from '../states/workflowChart';
import { Workflow, UpdateWorkflowData, ToastMessage, ModifyWorkflowDetails } from '../types';
import AddEditWorkflowModal from './AddEditWorkflowModal';
import { defaultWorkflowName } from '../../constants';
import WorkflowPublishModal from '../../components/publish-popup/WorkflowPublishPopup';
import { initialTryOutDetails, initialWorkflowDetails } from '../../WorkflowList';
import TryOutModal from '../../components/try-out-modal/TryOutModal';
import { Parameter } from '../../components/try-out-modal/types';
import { useTryOut } from '../../queries/useTryOut';
import useGetWorkflow from '../queries/useGetWorkflow';

const WorkflowCreationHeader = ({
  name,
  json,
  refetch,
  showSuccessPopup,
  setShowSuccessPopup,
}: {
  name: string;
  json: Workflow;
  showSuccessPopup: boolean;
  setShowSuccessPopup: (val: boolean) => void;
  refetch: () => void;
}): React.ReactElement => {
  const history = useHistory();
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const [workflowModified, setWorkflowModified] = useRecoilState(WorkflowModified);

  const [workflowName, setWorkflowName] = useState('');
  const [nameExistsError, setNameExistsError] = useState(false);
  const [showWorkflowNamePopup, setShowWorkflowNamePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [workflowDetails, setWorkflowDetails] = useState<ModifyWorkflowDetails>(initialWorkflowDetails);
  const [tryOutResponse, setTryOutResponse] = useState('');
  const [tryOutDetails, setTryOutDetails] = useState(initialTryOutDetails);
  const [transactionId, setTransactionId] = useState('');

  const { data: workflow } = useGetWorkflow(workflowDetails.workflowId);
  const [isAsyncExecution, setIsAsyncExecution] = useState(workflow?.isAsyncExecution ?? false);

  const WorkflowPublishToast = (): ReactElement => {
    return (
      <Box color="white" w="fit-content" bg="white" boxShadow="lg" borderRadius="lg" h="56px" pl={5} px={4}>
        <Flex h="full" alignItems="center">
          <Image src="/assets/icons/toast_tick.svg" />
          <Text fontSize="sm" fontWeight="normal" pl={3} color="gray.700">
            Workflow has been Updated Successfully
          </Text>
          <Text
            fontSize="xs"
            cursor="pointer"
            pl={6}
            fontWeight="normal"
            color="blue.900"
            onClick={() => {
              setShowSuccessPopup(true);
              toast.closeAll();
            }}
          >
            View Details
          </Text>
        </Flex>
      </Box>
    );
  };

  const { mutateAsync } = useUpdateWorkflow({
    onSuccess: data => {
      handleSuccess(data);
    },
    onError: error => {
      handleModifyWorkflowError(error);
    },
  });

  const handleModifyWorkflowError = (updateError: AxiosError): void => {
    if (updateError?.response?.status === CustomErrorCode.RECORD_EXISTS) {
      setNameExistsError(true);
      setShowWorkflowNamePopup(true);
    } else {
      setIsLoading(false);
      handleErrorToast(updateError);
    }
    setIsLoading(false);
  };

  const handleErrorToast = (error: AxiosError): void => {
    toast({
      description: error?.response?.data?.error?.message || ToastMessage.ERROR_MESSAGE,
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleSuccess = (data: UpdateWorkflowData): void => {
    setWorkflowModified(false);
    if (pathname.includes('create')) {
      setShowSuccessPopup(true);
      history.replace('edit');
    } else {
      toast({
        render: WorkflowPublishToast,
      });
    }
    setIsLoading(false);
    refetch();
  };

  const navigateBack = (): void => {
    history.push('/workflows');
  };

  const handleSaveWorkflow = (): void => {
    if (workflowName.indexOf(defaultWorkflowName) >= 0) setShowWorkflowNamePopup(true);
    else if (workflowModified) {
      mutateAsync({ id, workflow: { name: workflowName, isAsyncExecution, workflowObject: json } });
      setIsLoading(true);
    }
  };

  const onClose = (details: ModifyWorkflowDetails): void => {
    mutateAsync({ id, workflow: { name: details?.name, isAsyncExecution, workflowObject: json } });
    setIsLoading(true);
  };

  useEffect(() => {
    setWorkflowName(name);
  }, [name]);

  const getResponseContent = useMemo(
    (): ReactElement => (
      <Flex direction="column" alignItems="center">
        <Image src={`/assets/icons/${tryOutResponse}_response.svg`} mb={4} />
        <Box textAlign="center" mt={2}>
          {tryOutResponse === 'success'
            ? 'Transaction successfully executed'
            : 'Some error occurred while executing the transaction. Please try again.'}
        </Box>
      </Flex>
    ),
    [tryOutResponse],
  );

  const { mutateAsync: tryOutWorkflow } = useTryOut({
    onSuccess: response => {
      setTryOutResponse('success');
      setTransactionId(response?.transactionId);
      setSelectedAction('');
      setTryOutDetails(initialTryOutDetails);
    },
    onError: () => {
      setTryOutResponse('error');
      setSelectedAction('');
    },
  });

  const getTryOutDetails = (): { [key: string]: string } => {
    let response = {};
    tryOutDetails.forEach((parameter: Parameter) => {
      response = { ...response, [parameter.parameter]: parameter.value };
    });
    return response;
  };

  const onTryOutWorkflow = (): void => {
    const request = {
      workflowId: workflow?.workflowId,
      data: { ...getTryOutDetails() },
    };
    tryOutWorkflow(request);
  };

  const onActionPopupCancel = (): void => {
    setSelectedAction('');
    setWorkflowDetails(initialWorkflowDetails);
  };
  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        bg="white"
        minW="fit-content"
        px={1}
        borderWidth="1px"
        borderColor="blue.100"
        borderRadius="8rem"
        boxShadow="0px 4px 8px rgba(17, 25, 99, 0.04)"
        mx={6}
        my={4}
      >
        <Flex direction="column" pt="2" px="6">
          <Flex direction="row" alignItems="center">
            <CaretLeft
              onClick={navigateBack}
              size={20}
              style={{ marginTop: '2px', cursor: 'pointer', color: colors.gray[500] }}
            />
            <Box fontSize="md" color="gray.800" w={350}>
              <Input
                autoFocus
                value={workflowName}
                onChange={evt => {
                  setWorkflowName(evt.target.value);
                  if (evt.target.value !== name) setWorkflowModified(true);
                }}
                fontSize="md"
                _hover={{ bg: 'blue.100', cursor: 'pointer' }}
                _focus={{ outline: 'none' }}
                textAlign="left"
                border="none"
                fontWeight="500"
                aria-label="Edit Workflow Name"
              />
            </Box>
          </Flex>
          <Flex mt="-2" ml="2" alignItems="center">
            <Text fontWeight="light" fontSize="sm" color="gray.600" isTruncated>
              {id}
            </Text>
            <CopyButton text={id} id="copy-workflow" />
          </Flex>
        </Flex>

        <Flex flex={1} justifyContent="flex-end" gridGap="4">
          <FormControl display="flex" alignItems="center" w="unset">
            <FormLabel htmlFor="async-execution" mb="0" fontSize="sm">
              Enable Async Execution
            </FormLabel>
            <Switch
              id="async-execution"
              onChange={event => {
                setIsAsyncExecution(event.target.checked);
                setWorkflowModified(true);
              }}
              isChecked={isAsyncExecution}
            />
          </FormControl>
          <Flex
            bg="white.50"
            justifyContent="center"
            align="center"
            px="4"
            py="2"
            borderRadius="lg"
            cursor="pointer"
            h="12"
            onClick={() => setSelectedAction('Tryout')}
            id="tryout-workflow"
          >
            <Play size={22} color={colors.gray[800]} weight="fill" style={{ paddingTop: '2', marginRight: '3' }} />
            <Text>Tryout</Text>
          </Flex>
          <Button
            type="submit"
            colorScheme="blue"
            isLoading={isLoading}
            size="md"
            fontWeight="medium"
            borderRadius="lg"
            p="5"
            h="12"
            mr="4"
            disabled={workflowName.indexOf(defaultWorkflowName) < 0 && !workflowModified}
            onClick={handleSaveWorkflow}
            id="publish-workflow"
          >
            <RocketLaunch size={22} style={{ paddingLeft: '4', marginRight: '3' }} color="white" />{' '}
            {pathname.includes('edit') ? 'Update' : 'Publish'}
          </Button>
        </Flex>
      </Flex>
      {showWorkflowNamePopup && (
        <AddEditWorkflowModal
          details={{ name: workflowName, workflowId: id }}
          onClose={onClose}
          onCancel={() => setShowWorkflowNamePopup(false)}
          nameExistsError={nameExistsError}
          setNameExistsError={setNameExistsError}
          isLoading={isLoading}
          autoFocus
          publish
        />
      )}
      {showSuccessPopup && <WorkflowPublishModal onClose={() => setShowSuccessPopup(false)} id={id} />}
      {selectedAction === 'Tryout' && (
        <TryOutModal
          onCancel={() => {
            setTryOutDetails(initialTryOutDetails);
            onActionPopupCancel();
          }}
          onClose={onTryOutWorkflow}
          workflowId={workflowDetails.workflowId || id}
          tryOutDetails={tryOutDetails}
          setTryOutDetails={setTryOutDetails}
        />
      )}
      {tryOutResponse && (
        <AlertMessageModal
          alertContent={getResponseContent}
          onCancel={() => setTryOutResponse('')}
          onClose={() => {
            if (tryOutResponse === 'success') {
              history.push(`/reports/${transactionId}`);
            } else setTryOutResponse('');
          }}
          {...(tryOutResponse === 'success' && { buttonLabel: 'View Transaction' })}
        />
      )}
    </>
  );
};

export default WorkflowCreationHeader;
