/* eslint-disable import/no-extraneous-dependencies */
import { ChakraProvider } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { RecoilRoot } from 'recoil';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import AuthenticationProvider from './authentication/AuthenticationProvider';
import { AppRoutes } from './routes';
import { getTheme } from './utils/theme';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const App = (): ReactElement => {
  const theme = getTheme();
  return (
    <React.StrictMode>
      <RecoilRoot>
        <AuthenticationProvider>
          <ChakraProvider theme={theme}>
            <AppRoutes />
          </ChakraProvider>
        </AuthenticationProvider>
      </RecoilRoot>
    </React.StrictMode>
  );
};

export default Sentry.withProfiler(App);
